/* ------------------------------------------------------------------ */
/*        HOME2
/* ------------------------------------------------------------------ */


.clients {

    .header {

        display: flex;
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 3px;
        top: 0px;
        left: 0px;

        position: fixed; 

        background-color: $white;
        width: 100%;
        z-index: 100;

        .left_part, .right_part {

            @include sizeone {

                width: 100%;

            }

        }

        .left_part {

            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            .title {

                font-size: 50px;

                @include sizeone {

                    font-size: 14.2vw;
                    padding-top: 19px;
                    padding-bottom: 5px;
    
                }

            }

            @include sizeone {

                flex-direction: column;
                align-items: center;
    
            }

            .nav_item {

                @include sizeone {

                    position: absolute;
                    top: 4px;
                    left: 10px;
                    z-index: 1000;
                    opacity: 0;
                    transform: translateY(-117px);
                    pointer-events: none;

                    transition: opacity 0.3s ease,
                                transform 0.3s ease;

                }

            }

        }

        .burger {

            position: absolute;
            top: 0px;
            left: 10px;
            background: black;
            height: 19px;
            padding-top: 1.5px;
            width: 30px;

            transition: opacity 0.3s ease,
                        transform 0.3s ease;


            hr {

                width: 20px;
                background: white;
                box-shadow: none;
                border: none;
                height: 1px;
                margin: 3px 5px;

            }

        }

        .nav_item {

            font-size: 24px;
            padding-bottom: 4px;
            padding-left: 20px;
            cursor: pointer;

            @include sizeone {

                font-size: 1.15rem;
                text-align: left;
                padding-left: 5px;
                color: white;
                padding-bottom: 8px;
                

            }
            
            &:hover {

                color: $red;

                @include sizeone {

                    color: white;

                }

            }


            &.select {

                color: $red;

                @include sizeone {

                    color: $red!important;

                }

            }

        }

        .right_part {

            display: flex;
            justify-content: flex-end;
            align-items: flex-end;

            @include sizeone {

                position: absolute;
                left: 10px;
                top: 0px;
                flex-direction: column;
                align-items: flex-start;
                background-color: black;
                padding-top: 30px;
                padding-bottom: 0px;
                width: 141px;
                opacity: 0;
                transform: translateY(-117px);
                pointer-events: none;

                transition: opacity 0.3s ease,
                            transform 0.3s ease;

            }

        }

        .links_part {

            position: fixed;
            top: 0px;
            right: 20px;

            .links_item {

                font-size: 11px;
                padding: 4px 5px;
                background-color: black;
                color: white;

                &:hover {

                    color: $red;
    
                }

            }

        }

    }

    .projets {

        margin-top: 60px;

        @include sizeone {

            margin-top: 86px;

        }

        .projet {

            height: var(--height);
            
            overflow: hidden;

            transition: height 0.7s ease-out,
                        padding 0.7s ease-out;

            @include sizeone {

                min-height: 35vh;
                margin-top: -1px;

            }

            &.hide {

                padding-bottom: 0px;
                height: 0px;

            }

            .carousel_wrapper {

                width: 100vw;
                height: calc((100vw * var(--cth)) / var(--ctw));

            }

            .carousel {

                position: relative;

                .carousel-cell {

                    width: 100vw;
                    height: calc((100vw * var(--th)) / var(--tw));

                    img {
        
                        width: 100vw;
                        height: calc((100vw * var(--th)) / var(--tw));
                        padding: 0px 10px;
        
                    }
        
                }
        
                button.previous, button.next {
        
                    width: 50%;
                    position: absolute;
                    top: 0px;
                    height: 100%;
                    border: 0px;
                    background-color: transparent;

                    svg {

                        display: none;

                    }
        
                }

                button.previous {

                    left: 0px;
                    cursor: w-resize!important;

                }

                button.next {

                    right: 0px;
                    cursor: e-resize!important;

                }

                &.flickity-enabled:focus {
                    outline: none;
                }

            }
    
            .infos {
    
                display: flex;
                padding: 10px;

                @include sizeone {

                    flex-direction: column;
                    position: relative;

                }
    
                &_left, &_right {
    
                    width: 50%;

                    @include sizeone {

                        width: 100%;
            
                    }
    
                }

                &_right {

                    margin-bottom: 30px;

                }
    
                &_left {
    
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    @include sizeone {

                        flex-direction: column-reverse;
            
                    }
    
                    .title {
    
                        font-size: 24px;
    
                    }
    
                    .base {
    
                        display: flex;
                        padding-top: 3px;

                        @include sizeone {

                            margin-bottom: 20px;
                
                        }
    
                    }
        
                    .pagination {

                        margin-bottom: 30px;

                        @include sizeone {

                            position: absolute;
                            bottom: 9.5px;
                            right: 10px;
                            margin-bottom: 0px;
                                
                        }
        
                        svg {
        
                            width: 10px;
                            height: 10px;
        
                            circle {
        
                                fill: $white;
                                stroke: $black;
                                stroke-width: 25px;
        
                            }
    
                            &:first-child > circle {
    
                                fill: $black;
    
                            }
        
                        }
        
                    }
    
                }
    
                .link_wrapper {
    
                    margin-top: 18.5px;
    
                }
    
            }
    
        }
    
        hr {
    
            padding: 0px 10px;
            width: calc(100% - 20px);
            background-color: black;
            height: 2.5px;
            border: none;
            margin-bottom: 0px;
            margin-top: 0px;
            z-index: 10;
            position: relative;
    
        }

    }

}

.burger_open {

    .burger {

        opacity: 0;
        pointer-events: none;
        transform: translateY(-40px);

    }

    .right_part {

        opacity: 1!important;
        pointer-events: auto!important;
        transform: translateY(-1px)!important;

    }

    .left_part {

        .nav_item {

            opacity: 1!important;
            pointer-events: auto!important;
            transform: translateY(-1px)!important;

        }

    }

}