/* ------------------------------------------------------------------ */
/*        FONT FACE DECLARATION
/* ------------------------------------------------------------------ */

@font-face {
    font-family: 'Thein';
    src: url(http://localhost:8080/assets/fonts/Theinhardt-Condensed-Bold.woff2),
         url(http://localhost:8080/assets/fonts/Theinhardt-Condensed-Bold.woff) format('woff'),
         url(http://localhost:8080/assets/fonts/Theinhardt-Condensed-Bold.otf) format('opentype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Haas';
    src: url(http://localhost:8080/assets/fonts/regular.woff2),
         url(http://localhost:8080/assets/fonts/regular.woff) format('woff'),
         url(http://localhost:8080/assets/fonts/regular.otf) format('truetype');
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Haas';
    src: url(http://localhost:8080/assets/fonts/italic.woff2),
         url(http://localhost:8080/assets/fonts/italic.woff) format('woff'),
         url(http://localhost:8080/assets/fonts/italic.otf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

