/* ------------------------------------------------------------------ */
/*        General HTML - BODY
/* ------------------------------------------------------------------ */
// * {
//     outline: none;
//     -ms-overflow-style: none;  // IE 10+
//     overflow: -moz-scrollbars-none;  // Firefox
//     &::-webkit-scrollbar {
//         display: none;
//     }
// }

/* ------------------------------------------------------------------ */
/*        HEADER
/* ------------------------------------------------------------------ */

footer {

    .credits {

        display: flex;
        padding-left: 10px;
        justify-content: flex-start;
        width: 100%;
        align-items: center;
        font-size: 0.8rem;

        a {

            padding: 10px;
            padding-left: 5px;
            cursor: pointer;

        }

    }

}

a:hover {

    color: $red;

}


/* ------------------------------------------------------------------ */
/*        Preloader
/* ------------------------------------------------------------------ */
// .banner {

//     position: fixed;
//     width: 400px;
//     height: 200px;
//     top: 50%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     background-color: #f8f8f8;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;
//     padding: 30px;
//     text-align: center;
//     padding-bottom: 0px;
//     border-radius: 10px;
//     -webkit-box-shadow: 0px 1px 11px -3px #2e2e2e;
//     box-shadow: 0px 1px 11px -3px #2e2e2e;
//     z-index: 1000000;
//     display: none;

//     @include sizeone {

//         width: 88vw;

//     }

//     &.show {

//         display: flex;

//     }

//     a {

//         position: relative;

//         .under {

//             background-color: black;
//             width: 100%;
//             position: absolute;
//             bottom: -1px;
//             left: -1px;
//             height: 1px;
//             transform: scaleX(1);
//             transform-origin: 0% 0;
//             pointer-events: none;
//             transition: transform 0.3s $Power3EaseInOut,
//                         background-color 0.3s $Power3EaseInOut;

//         }

//         &:hover {

//             .under {

//                 transform: scaleX(0);
//                 transform-origin: 100% 0;

//             }

//         }

//     }

// }

/* ------------------------------------------------------------------ */
/*        404
/* ------------------------------------------------------------------ */

.error_page {

    height: calc(100vh - 313px);

    .error {

        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        a {

            text-transform: uppercase;
        }

    }

}