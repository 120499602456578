/* ------------------------------------------------------------------ */
/*        HEADING DECLARATION
/* ------------------------------------------------------------------ */



* {
    box-sizing: border-box;
    text-rendering: optimizeLegibility;
    text-rendering: geometricPrecision;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    background-repeat: no-repeat;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}

html {

    font-size: 1rem;
    color: $black;
    background-color: $white;
    font-family: sans-serif;
    height: 100vh;

}


// body {

//     background-color: $red;
//     color: $white;
//     margin: 30px;

//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     height: calc(100vh - 168px);


//     @include sizeone {

//         height: 100%;
//         margin: 15px;

//     }

// }

// PREV PART

body {

    color: $black;

}

.desk {

    @include sizeone {

        display: none!important;

    }

}

.mob, .mob-flex {

    display: none!important;

}

.mob {

    @include sizeone {

        display: block!important;

    }
}

.mob-flex {

    display: none!important;

    @include sizeone {

        display: flex!important;

    }

}

